import { graphql, useStaticQuery } from 'gatsby';

export const useGiftCardText = () => {
  const { directusGiftCardProductPage } = useStaticQuery(graphql`
    query GiftCardTex {
      directusGiftCardProductPage {
        page_title
        page_description
        hero_title
        hero_description
        gift_header
        gift_subheader
        gift_text
        faq_description
        faqs {
          question
          answer
        }
      }
    }
  `);
  return directusGiftCardProductPage;
};
