import React, { useState } from 'react';

import Layout from '@components/layout';
import SEO from '@components/seo';
import { useGiftCardText } from '@hooks/directusHooks/giftCardText';
import StillHaveQuestion from '@components/still-have-question/still-have-question';
import AddToBasket from '@components/add-to-basket';

import styles from './gift-card.module.scss';

const GIFT_CARDS = [
  { label: '£10', value: 'vitl-gift-card-10' },
  { label: '£25', value: 'vitl-gift-card-25' },
  { label: '£50', value: 'vitl-gift-card-50' },
  { label: '£100', value: 'vitl-gift-card-100' },
];

const GiftCard = () => {
  const [active, setActive] = useState(GIFT_CARDS[0].value);
  const text = useGiftCardText();

  return (
    <Layout>
      <SEO title={text.page_title} description={text.page_description} />
      <div className={styles.section}>
        <div className={styles.left}>
          <img src="/images/Card+Dots.png" alt="" />
        </div>
        <div className={styles.right}>
          <h1>{text.hero_title}</h1>
          <hr />
          <h6>{text.hero_description}</h6>
        </div>
      </div>
      <div className={styles.white}>
        <div className={styles.container}>
          <h2>{text.gift_header}</h2>
          <hr />
          <div className={styles.cards}>
            {GIFT_CARDS.map(card => (
              <div
                key={card.value}
                className={`${styles.card} ${active === card.value ? styles.active : ''}`}
                onClick={() => setActive(card.value)}
              >
                {card.label}
              </div>
            ))}
          </div>
          <AddToBasket isBundle={true} showButtonOnly={true} sku={active} />
          <h4>{text.gift_subheader}</h4>
          <p>{text.gift_text}</p>
        </div>
      </div>
      <StillHaveQuestion accordion={text.faqs} description={text.faq_description} />
    </Layout>
  );
};

export default GiftCard;
